<div class="container">
    <div class="d-flex justify-content-center" *ngIf="isLoading">
        <app-loading></app-loading>
    </div>
    <div *ngIf="!jobsAvailable && !isLoading">
        <h2>UH OH!</h2>
        <p> You do not have any Jobs.</p>
        <button type="button" class="btn btn-info mb-3" routerLink="/find-work">FIND WORK</button>
    </div>
    <div *ngIf="jobsAvailable && !isLoading">
        <div class="card" *ngFor="let job of jobs | paginate : jobsPagingConfig; let i = index">
            <div class="card-body">
                <h5 class="card-title"><a [href]="'/job/' + job._id">{{job.title}}</a></h5>
                <p class="card-subtitle mb-2 text-body-secondary">{{job.description}}</p>
                <div class="row mb-3">
                    <div class="col-md-3">
                        <p class="card-text"><strong>Posted: </strong>{{job.created_on | date: 'mediumDate'}}</p>
                    </div>
                    <div class="col-md-3">
                        <p class="card-text"><strong>Amount Due: </strong>{{job.freelancer_amount }} USD</p>
                    </div>
                    <div class="col-md-3">
                        <p class="card-text"><strong>Status: </strong>{{job.status}}</p>
                    </div>
                    <div class="col-md-3">
                        <p class="card-text"><strong>Percent Paid By client: </strong>{{(job.amountPaid/job.budget)*100}}%</p>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-3">
                        <p class="card-text"><strong>Milestones: </strong>{{job.milestones.length}}</p>
                    </div>
                    <div class="col-md-3">
                        <p class="card-text"><strong>Deadline: </strong>{{job.deadline | date: 'mediumDate'}}</p>
                    </div>
                    <div class="col-md-3">
                        <p class="card-text applied">Applied On: {{job.freelancer.applied_on | date: 'mediumDate'}}</p>
                    </div>
                    <div class="col-md-3">
                        <p class="card-text applied" *ngIf="job.status === 'Completed'">Completed On: {{job.approved_on | date: 'mediumDate'}}</p>
                    </div>
                </div>
                <p class="card-text"><strong>Required Skills: </strong>{{job.skills}}</p>
            </div>
        </div>
        <div class="d-flex justify-content-center paginator">
            <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onjobsDataChange($event)">
            </pagination-controls>
        </div>
    </div>
</div>